import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { BlogRecentCard, ContainerList, Feature, PageLayout, TalkWithExpert } from '../components'

const Home = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {in: [
        "00-feature-connected.png",
        "00-feature-automated.png",
        "00-feature-analytics.png",
        "00-hero-index.png"
      ]}}) {
        images: edges {
          node {
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      posts: allMarkdownRemark(
        filter: {
          frontmatter: {
            templateKey: {eq: "blog"},
            tags: {eq: "Case Study"}
          }
        }, 
        sort: {fields: [frontmatter___order, frontmatter___date], 
        order: [ASC, DESC]},
        limit: 3
      ) {
        edges {
          node {
            id
            timeToRead
            frontmatter {
              header
              title
              description
              date
              relativeDate: date(fromNow: true)
              featuredimage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              featuredpost
              tags
            }
            fields {
              slug
            }
            excerpt(pruneLength: 160)
            html
          }
        }
      }
    }
  `)

  const fluidImage = (path) => {
    const images = data.allFile.images
    const imageNode = images.find(item => item.node.relativePath === path)
    return imageNode && imageNode.node.childImageSharp.fluid
  }

  return (
    <PageLayout 
      location={location}
      title="Automated Data Integration"
      description="Easily connect your existing BI tools to all your business data, SaaS applications, files, and databases."
      backgroundColor="#fff"
      pageTheme="dark"
      align="center"
      footer={true}
      canonical={location.origin}
      pageHero={{
        section: {
          margin: { horizontal: "11vw" }
        },
        main: {
          heading: {
            label: "Your distributed business data, reliably centralized and ready for analysis.",
          },
          subHeading: {
            label: "Start analyzing data in minutes instead of days, weeks, or months. Create a centralized repository of all your enterprise business data to deliver dashboards, reports and analysis in record time.",
          },
          button: {
            path: "/contact/schedule-demo",
            label: "Talk with an Expert"
          },
          style: { flex: "0 1 55%" },
        },
        aside: {
          content: (
            <Img fluid={fluidImage("00-hero-index.png")} />
          ),
          style: { flex: "0 1 45%" }
        }
      }}
    >
      <Feature 
        themeColor="#f6f7fb"
        image={{
          type: "fluid",
          content: fluidImage("00-feature-analytics.png"),
          width: "580px",
          style: { bottom: "10%", left: "8%" }
        }}
        aside={{
          header: "Do More With Less",
          title: "Created for enterprise analytic teams who demand to move at the speed of business.",
          description: "Hippobyte's pre-built connectors deliver your business data into tidy tables within your data warehouse to empower analytic teams and free up engineering teams.",
          actionLabel: "explore connectors...",
          actionPath: "/connectors"
        }}
      />
      <Feature 
        themeColor="#f1fcff"
        reverse
        image={{
          type: "fluid",
          content: fluidImage("00-feature-automated.png"),
          width: "460px",
          style: { bottom: "12%", right: "8%" }
        }}
        aside={{
          header: "Financial Reporting",
          title: "Automate financial reports and month-end activities with trusted data.",
          description: "Hippobyte helps avoid common mistakes introduced into financial reports by eliminating error-prone data extracts and manipulations tasks. Spend more time analyzing, less time validating.",
          actionLabel: "more...",
          actionPath: "/solutions/finance"
        }}
      />
      <Feature 
        themeColor="#f7fbf6"
        image={{
          type: "fluid",
          content: fluidImage("00-feature-connected.png"),
          width: "500px",
          style: { bottom: "10%", left: "12%" }
        }}
        aside={{
          header: "Data-Driven Decisions",
          title: "Deploy multi-source dashboards and reports stakeholders trust and value.",
          description: "Hippobyte delivers data from multiple SaaS applications, databases, and files so analysts can empower decision-makers with actionable metrics from several sources."
        }}
      />
      <ContainerList
        round="xsmall"
        fontSize="large"
        color="black"
        basis="1/3"
        header={{
          title: "What makes Hippobyte different?",
          subTitle: "Quality data, faster, in the right hands, ready for analysis.",
        }}
        items={[
          {
            title: "Quality Data",
            description: "Hippobyte's fully-managed platform resolves common data delivery issues automatically, allowing you to allocate more time to delivering business outcomes.",
            image: { 
              theme: "black",
              src: "TickBox",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "Actionable Data",
            description: "Hippobyte's up-to-the-minute data delivery helps organizations make timely decisions based on better data.",
            image: { 
              theme: "black",
              src: "TrackField",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "Unified Data",
            description: "Hippobyte reliably delivers data from SaaS applications, databases, and files to a centralized repository for comprehensive insights.",
            image: { 
              theme: "black",
              src: "ThreeSixty",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "No-code Business Logic",
            description: "Eliminate complex configurations and engineering tasks. Hippobyte's Console allows analysts to create complex data pipelines with just a few clicks.",
            image: { 
              theme: "black",
              src: "Schema",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "Existing Tools Just Work",
            description: "Don't `invest` in yet another business intelligence tool. Hippobyte works with your existing platforms, PowerBI, Tableau, Looker, and similar.",
            image: { 
              theme: "black",
              src: "HeartBaloon",
              position: "top",
              size: "58px"
            },
          },
          {
            title: "Escape Application Data Silos",
            description: "Hippobyte enables you to access and replicate SaaS application data to a database you own for added control over your data.",
            image: { 
              theme: "black",
              src: "Puzzle",
              position: "top",
              size: "58px"
            },
          },
        ]}
      />
      <BlogRecentCard 
        title="Learn how data analytics teams use Hippobyte."
        articles={data.posts.edges}
      />
      <TalkWithExpert />
    </PageLayout>
  )
}

export default Home